
.react-datepicker {
    box-sizing: border-box;
    font-size: 1.3rem !important;
    border: solid 1px #e7e5e4 !important;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1) !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__input-container {
    width: 100% !important;
}

.react-datepicker__current-month {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
    font-weight: 600 !important;
    color: #292524 !important;
    margin-top: 2px !important;
}

.react-datepicker__header {
    padding-top: 6px !important;
    background-color: #f5f5f4 !important;
    border-bottom: solid 1px #e7e5e4 !important;
}

.react-datepicker__navigation {
    font-size: 2rem !important;
    top: 13px !important;
}

.react-datepicker__day-name {
    font-size: 0.875rem !important;
    color: #78716c !important;
}

.react-datepicker__day {
    font-size: 1rem !important;
    color: #44403c !important;
    font-weight: 400;
}

.react-datepicker__day-name, .react-datepicker__day {
    width: 2.5rem  !important;
    height: 2.5rem  !important;
    line-height: 2rem;
    font-family: inherit !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    background-color: #fdba74 !important;
    color: #9a3412 !important;
    border-radius: 100% !important;
}

.react-datepicker-popper {
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100 !important;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: none !important;
    transition: all 0.3s ease;
    height: 100vh !important
}

.react-datepicker__triangle {
    display: none !important;
}
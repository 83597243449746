.time-field {
   --dropdown-border: 1px solid #fed7aa;
   --dropdown-hover-bg: #fed7aa;
   --meridiem-selected-bg-color: #fdba74;
   --meridiem-text-color: #1c1917;
   --meridiem-selected-text-color: #1c1917;

   --top-colon-color: #1c1917;
   --top-text-color: #1c1917;
   --top-selected-color: #f97316;
   --top-meridiem-color: #292524;

   --hand-line-color: #fdba74;
   --hand-circle-center: #fdba74;
   --hand-circle-outer: #fdba74;
   --hand-minute-circle: #fdba74;

   --numbers-text-color: #292524;

   --done-bg-color: white;
   --done-text-color: #f97316;
   --done-font-size: 0.75rem;
   --done-font-weight: 600;
}

.ReactModal__Content {
   border: none !important;
}

.react-timekeeper {
   font-family: inherit !important;
}

.react-timekeeper__tb-hour, .react-timekeeper__tb-colon, .react-timekeeper__tb-minute {
   font-size: 2.2rem !important;
   font-weight: 600 !important;
}

.react-timekeeper-button-reset {
   font-size: 0.75rem !important;
   font-weight: 600 !important;
}

.react-timekeeper__done-button {
   font-weight: 700 !important;
   border-top: 1px solid #e7e5e4 !important;
}

.react-timekeeper__clock-wrapper {
   background-color: #d6d3d1;
   border-top: 1px solid #e7e5e4;
}
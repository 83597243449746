/* autoprefixer grid: autoplace */

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
    .scrollbar-visible {
        overflow-y: auto;
        height: 100px;
    }

    .scrollbar-visible::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .scrollbar-visible::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

}

@import "../components/ui/TimeField/styles.css";
@import "../components/ui/DateField/styles.css";

html {
    scroll-behavior: smooth !important;
}

.scroll-offset {
    scroll-margin-top: 100px;
}

.invisible-scroll::-webkit-scrollbar
{
    width: 0;
}

.scrollbar-always-visible {
    scrollbar-width: auto; /* For Firefox */
    -ms-overflow-style: auto; /* For Internet Explorer and Edge */
}

.scrollbar-always-visible::-webkit-scrollbar {
    width: 8px;
    height: 20px;
    display: block; /* For Chrome, Safari, and Opera */
}

.scrollbar-always-visible::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 10px;
}

.scrollbar-always-visible::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
/* ReactModal */
.ReactModal__Overlay {
    z-index: 5;
    opacity: 0;
    transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}


[data-whatintent='keyboard'] * {
    outline-color: orange;
    outline-offset: 4px;
}

[data-whatintent='keyboard'] .albertsons * {
    outline-color: #E06A7A !important;
    outline-offset: 4px;
}

[data-whatintent='mouse'] .outline-none-mouse:focus {
    outline: none;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}


/* Vimeo */

lite-vimeo {
    font-size: 10px;
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
}

/* gradient, vimeo doesn't have this */
/*lite-vimeo::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
    background-position: top;
    background-repeat: repeat-x;
    height: 60px;
    padding-bottom: 50px;
    width: 100%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}*/

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
lite-vimeo::after {
    content: "";
    display: block;
    padding-bottom: calc(100% / (16 / 9));
}
lite-vimeo > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* play button */
lite-vimeo > .ltv-playbtn {
    width: 6.5em;
    height: 4em;
    background: #9a3412;
    z-index: 1;
    opacity: 0.8;
    border-radius: .5em; /* TODO: Consider replacing this with YT's actual svg. Eh. */
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    outline: 0;
    border: 0;
    cursor: pointer;

}
lite-vimeo:hover > .ltv-playbtn {
    background-color: #ea580c;
    opacity: 1;
}
/* play button triangle */
lite-vimeo > .ltv-playbtn::before {
    content: '';
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #fff;
}

lite-vimeo > .ltv-playbtn,
lite-vimeo > .ltv-playbtn::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
lite-vimeo.ltv-activated {
    cursor: unset;
}
lite-vimeo.ltv-activated::before,
lite-vimeo.ltv-activated > .ltv-playbtn {
    opacity: 0;
    pointer-events: none;
}

/* PARALLAX */
.parallax {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px), (pointer: coarse)  {
    .parallax {
        background-attachment: scroll;
    }
}

.transparent-notice a {
    color: white;
    font-weight: bolder;
}

input[type="time"]::-webkit-clear-button {
    display: none;
}

.gm-style iframe + div { border: none!important; }

.gm-style-iw {
    width: 300px;
    min-height: 150px;
}